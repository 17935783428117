import Styles from "./Job.module.scss";
import classNames from "classnames/bind";

interface JobProps {
    className: string,
    jobName: string,
    jobDescription?: string|null,
    requirements: Array<string>,
    offers: Array<string>
}

const Job = ({
    jobName,
    jobDescription,
    requirements,
    offers,
    className,
}: JobProps) => {
    const cx = classNames.bind(Styles);

    return (
        <>
            <div className={cx("job", className)}>
                <h3 className={cx("job__name")}>{jobName}</h3>
                {jobDescription && <p className={cx("job__description")}>{jobDescription}</p>}

                <div className={cx("job__offers-requirements")}>
                    <div className={cx("job__requirements-list")}>
                        <div className={cx("job__requirements-list__title")}>Požadujeme:</div>
                        <ul>
                            {requirements.map(requirement => {
                                return <li>{requirement}</li>
                            })}
                        </ul>
                    </div>
                    <div className={cx("job__offers-list")}>
                        <div className={cx("job__offers-list__title")}>Nabízíme:</div>
                        <ul>
                            {offers.map(offer => {
                                return <li>{offer}</li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>

    )

}

export default Job;