import React from "react";
import Styles from "./Hero.module.scss";
import classNames from "classnames/bind";
import { IconType } from "react-icons/lib";

interface HeroIcon {
  iconComponent: IconType;
  iconOnClick(): void;
}

interface HeroProps {
  title: string;
  subtitle: string;
  background: string;
  icon?: HeroIcon;
  className?: string;
}

const Hero = ({ title, subtitle, background, className, icon }: HeroProps) => {
  const cx = classNames.bind(Styles);
  return (
    <div
      className={cx("hero", className)}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url(${background})`,
      }}
    >
      <div className={cx("hero__text-container")}>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        {icon && (
          <icon.iconComponent fontSize={35} onClick={icon.iconOnClick} />
        )}
      </div>
    </div>
  );
};

export default Hero;
