import { useState } from "react";
import { NavLink } from "react-router-dom";
import Styles from "./Navbar.module.scss";
import classNames from "classnames/bind";
interface NavbarProps {
  links: Array<Link>;
  headerImage: string;
}

interface Link {
  external?: boolean;
  title: string;
  path: string;
}

const Navbar = ({ links, headerImage }: NavbarProps) => {
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const cx = classNames.bind(Styles);

  return (
    <nav className={cx("navbar")}>
      <div className={cx("navbar__header")}>
        <a href="/" onClick={() => setdropdownOpen(false)}>
          <img
            className={cx("navbar__image")}
            src={headerImage}
            alt="Holoubek trade logo"
          />
        </a>
        <div
          className={cx(
            "navbar__toggle-button",
            dropdownOpen
              ? "navbar__toggle-button--open"
              : "navbar__toggle-button--close"
          )}
          role="button"
          onClick={() => setdropdownOpen(!dropdownOpen)}
        >
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
      <ul
        className={cx(
          "navbar__links-menu",
          dropdownOpen
            ? "navbar__links-menu--open"
            : "navbar__links-menu--closed"
        )}
      >
        {links.map((link) => {
          return (
            <li key={link.title} onClick={() => setdropdownOpen(false)}>
              {link.external ? (
                <a href={link.path} target="_blank" rel="noreferrer">
                  {link.title}
                </a>
              ) : (
                <NavLink to={link.path}>{link.title}</NavLink>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
