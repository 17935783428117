import classNames from "classnames/bind";
import Styles from "./Footer.module.scss";
import { IconType } from "react-icons";

interface FooterProps {
  socials: Array<Social>;
  bottomText: string;
  googleMapsIframeSource: string;
  images?: Array<string>;
  variant?: "metalwork" | "trailers" | "fences" | "default";
  className?: string;
}

interface Social {
  name: string;
  text: string;
  icon: IconType;
  link: string;
  backgroundColor?: string;
}

const Footer = ({
  socials,
  className,
  googleMapsIframeSource,
  bottomText,
  images,
  variant
}: FooterProps) => {
  const cx = classNames.bind(Styles);

  return (
    <footer id="footer" className={cx("footer", `footer--${variant}`, className)}>
      <div className={cx("footer__sections")}>
        <div className={cx("footer__contacts-section")}>
          <h2>Kontakty</h2>
          <div className={cx("footer__socials")}>
            {socials.map((social) => {
              return (
                <a key={social.name} target="_blank" href={social.link} rel="noreferrer">
                  <div className={cx("footer__social-container")}>
                    <div
                      style={{ backgroundColor: social.backgroundColor }}
                      className={cx("footer__social-icon")}
                    >
                      <social.icon />
                    </div>

                    <div className={cx("footer__social-text")}>
                      {social.text}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <div className={cx("footer__map-section")}>
          <h2>Najdete nás zde</h2>
          <div className={cx("footer__map-frame-container")}>
            <iframe
              className={cx("footer__map-frame")}
              src={googleMapsIframeSource}
              title="Google Maps"
            />
          </div>
        </div>
        {images &&
          <div className={cx("footer__images-section")}>
            <h2>Podpora EU</h2>
            <div className={cx("footer__images-container")}>
              {images.map(image => {
                return <a href={image} key={image} target="_blank" rel="noreferrer"><img src={image} alt="" /></a>
              })}
            </div>
          </div>}
      </div>

      <div className={cx("footer__authors")}>{bottomText}</div>
    </footer>
  );
};

export default Footer;
