import Styles from "./PersonalContact.module.scss";
import classNames from "classnames/bind";

export interface PersonalContactProps {
  name: string;
  job: string;
  phone: string;
  phone_href: string;
  mail: string;
  className?: string;
}

const PersonalContact = ({
  name,
  job,
  phone,
  phone_href,
  mail,
  className,
}: PersonalContactProps) => {
  const cx = classNames.bind(Styles);

  return (
    <div className={cx("personal-contact", className)}>
      <h3 className={cx("personal-contact__person")}>{`${name} - ${job}`}</h3>
      <p>
        <a
          className={cx("personal-contact__mail")}
          target="_blank"
          href={`mailto:${mail}`}
          rel="noreferrer"
        >
          <span className={cx("personal-contact__mail-text")}>{mail}</span>
        </a>
      </p>
      <p>
        <a
          key="Phone"
          className={cx("personal-contact__phone")}
          target="_blank"
          href={`tel:${phone_href}`}
          rel="noreferrer"
        >
          <span className={cx("personal-contact__phone-text")}>{phone}</span>
        </a>
      </p>
    </div>
  );
};

export default PersonalContact;
