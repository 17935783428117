import classNames from "classnames/bind";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Footer from "../Footer/Footer";
import Styles from "./HTFooter.module.scss";


const googleMapsIframeSource =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2588.456424406146!2d15.95541!3d49.5514106!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d70fb315ad13f%3A0x3f94c5f75b949413!2sHoloubek%20trade%20s.r.o.!5e0!3m2!1scs!2scz!4v1661376100973!5m2!1scs!2scz";

const socials = [
  {
    name: "Email",
    text: "obchod@holoubektrade.cz",
    icon: MdEmail,
    link: "mailto:obchod@holoubektrade.cz",
    backgroundColor: "red",
  },
  {
    name: "Phone",
    text: "+420 775 555 830",
    icon: FaPhoneSquareAlt,
    link: "tel:+420-775-555-830",
    backgroundColor: "#25D366",
  },
  {
    name: "Instagram",
    text: "@holoubek_trade",
    icon: BsInstagram,
    link: "https://www.instagram.com/holoubek_trade/",
    backgroundColor: "#ef6644",
  },
  {
    name: "Facebook",
    text: "Holoubek trade",
    icon: BsFacebook,
    link: "https://www.facebook.com/holoubektrade",
    backgroundColor: "#4267B2",
  },
];

const cx = classNames.bind(Styles);

interface HTFooterProps{
  className: string
}

const HTFooter = ({className} : HTFooterProps) => {
  return (<Footer
    bottomText="Code &amp; Design by Tomáš Beneš and Josef Kotoun"
    images={["/images/eu1.jpg", "/images/eu2.jpg", "/images/eu3.jpg"]}
    socials={socials}
    className={cx(className)}
    googleMapsIframeSource={googleMapsIframeSource}
    variant="default"
  />)
};

export default HTFooter;
