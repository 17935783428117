import { useRef, useEffect } from "react";
import classNames from "classnames/bind";
import Styles from "./Kovovyroba.module.scss";
import Hero from "../../components/Hero/Hero";
import ContentBox from "../../components/ContentBox/ContentBox";
import Button from "../../components/Button/Button";
import { FaChevronCircleDown } from "react-icons/fa";
import { BiCommentDots } from "react-icons/bi";
import DemandForm from "../../components/DemandForm/DemandForm";
import useOnScreen from "../../hooks/useOnScreen";
import { polyfill, scrollIntoView } from "seamless-scroll-polyfill";
import HTFooter from "../../components/HTFooter/HTFooter";

const Kovovyroba = () => {
  const cx = classNames.bind(Styles);

  const demandFormRef = useRef(null);
  const demandFormInViewport = useOnScreen(demandFormRef);
  
  //scrolls to referenced element
  const scrollToRef = (ref: Element | null) => {
    if (ref != null) {
      scrollIntoView(ref, { behavior: "smooth", block: "start" });
    }
  };

  //load element references for smooth scroll
  useEffect(() => {
    polyfill();
  }, [])
  return (
    <>
      <Button
        id="demandFormRefButton"
        variant="metalwork"
        onClick={() => scrollToRef(document.querySelector("#demandForm"))}
        className={cx(
          "demand-form-scroll-button",
          demandFormInViewport && "demand-form-scroll-button--hidden"
        )}
      >
        <BiCommentDots /> &nbsp; Poptat výrobu
      </Button>

      <Hero
        icon={{
          iconComponent: FaChevronCircleDown,
          iconOnClick: () => scrollToRef(document.querySelector("#contentContainer")),
        }}
        background="/images/robot.jpg"
        title="Rychlost, kvalita a flexibilita"
        subtitle="Seznamte se s našimi stroji"
      />
      <div className={cx("page-container")}>
        <div className={cx("content-container")}  id="contentContainer">
          <ContentBox
            imageFirst={false}
            imageUrl="/images/laser.jpg"
            className={"content-box--separated"}
            html={
              <>
                <p>
                  Řežeme plechy do rozměrů velkých formátů 3000 x 1500 mm.
                  Maximální průřezová tloušťka je 20 mm u černé oceli, 10 mm u
                  nerezové oceli a 10 mm u hliníku. Parametry pro ostatní
                  materiály jsou individuální.
                </p>
                <p>
                  Laserem umíme řezat i kulaté, obdélníkové, čtvercové a jiné
                  profily. V rotační ose je lze upnout do průměru 400 mm.
                </p>
              </>
            }
            title="Řezání plechů a profilů"
            variant="metalwork"
          />
          <ContentBox
            imageFirst={true}
            className={"content-box--separated"}
            imageUrl="/images/ohranovac.jpg"
            html={
              <p>
                Maximální délka ohybu je 3000 mm a v ní ohneme plech z černé oceli v tloušťce 6 mm, z nerezové oceli 5 mm a z hliníku 8 mm. Ohnutí materiálu v menší délce, ale zato větší tloušťce je samozřejmostí.
              </p>
            }
            title="Ohraňování"
            variant="metalwork"
          />
          <ContentBox
            imageFirst={false}
            className={"content-box--separated"}
            imageUrl="/images/robot.jpg"
            html={
              <p>
                Svařujeme metodou TIG i MIG/MAG, které používáme zejména k výrobě železných konstrukcí. Nabízíme také sériové (velkoobjemové) svařování jednoduchých produktů pomocí svařovacích robotů KUKA.
              </p>
            }
            title="Svařování"
            variant="metalwork"
          />
          <ContentBox
            imageFirst={true}
            className={"content-box--separated"}
            imageUrl="/images/pika.jpg"
            html={
              <p>
                Nabízíme řezání profilů na naší nové pilce BOMAR, která umožňuje oboustranné úhlové řezy a dělení materiálu až do průměru 400 mm.
              </p>
            }
            title="Řezání"
            variant="metalwork"
          />
          <ContentBox
            imageFirst={false}
            imageUrl="/images/3d-tisk.jpg"
            html={
              <p>
                Provádíme aditivní výrobu velkých a robustních dílů z běžných i technických materiálů vhodných pro průmyslové použití. Tiskový objem je 420 x 300 x 220 mm a její předností jsou dvě částečně nezávislé tiskové hlavy IDEX, které zajišťují dvojnásobně rychlý tisk. Zde Vám nabízíme individuální konzultaci a výrobu.
              </p>
            }
            title="3D tisk"
            className={"content-box--separated"}
            variant="metalwork"
          />
          <div id="demandForm" ref={demandFormRef} className={cx("demand-form--separated")}>
            <DemandForm
              variant="metalwork"
              gdprLink="gdpr"
              className={cx("metalwork-demand-form")}
            />
          </div>

         <HTFooter className={cx("footer")}/>
        </div>
      </div>
    </>
  );
};

export default Kovovyroba;
