import { useEffect } from "react";
import classNames from "classnames/bind";
import Styles from "./Kariera.module.scss";
import Hero from "../../components/Hero/Hero";
import { FaChevronCircleDown } from "react-icons/fa";
import { polyfill, scrollIntoView } from "seamless-scroll-polyfill";
import HTFooter from "../../components/HTFooter/HTFooter";
import Job from "../../components/Job/Job";
const Kariera = () => {
    const cx = classNames.bind(Styles);
    const scrollToRef = (ref: Element | null) => {
        if (ref != null) {
            scrollIntoView(ref, { behavior: "smooth", block: "start" });
        }
    };

    useEffect(() => {
        polyfill();
    }, [])

    const jobs = [
        {
            jobName: 'Konstruktér přívěsů',
            jobDescription: '',
            requirements: [
                'vzdělání technického směru - Strojírenství',
                'znalost 2D/3D CAD systémů',
                'časovou flexibilitu, pečlivost, ochotu učit se novým věcem',
                'spolehlivost, zodpovědnost'
            ],
            offers: [
                'nástupní plat 32.000,- až 38.000 ,- + prémie',
                'rostoucí mzdové ohodnocení dle přínosu',
                'práce v přátelském kolektivu',
                'příspěvek na závodní stravování',
                '2 dny sickdays',
                'zaměstnanecká sleva',
                'firemní SIM karta'
            ]
        },
        {
            jobName: "Řidič/skladník",
            jobDescription: '',
            requirements: [
                "řidičský průkaz B, B+E, C, C+E", "profesní průkaz",
                "časovou flexibilitu, spolehlivost a zodpovědnost",
                "praxe výhodou"
            ],
            offers: [
                "nástupní plat 28.000,- až 36.000,- + prémie",
                "rostoucí mzdové ohodnocení dle přínosu",
                "práce v přátelském kolektivu",
                "příspěvek na závodní stravování",
                "2 dny sickdays",
                "zaměstnanecká sleva",
                "firemní SIM karta",
            ]
        },
        {
            jobName: "Obsluha CNC ohraňovacího lisu / laseru",
            jobDescription: '',
            requirements: [
                "vzdělání technického směru - Strojírenství (není podmínkou)",
                "praxe v obsluze CNC strojů výhodou",
                "spolehlivost a zodpovědnost",
                "pečlivost",
            ],
            offers: [
                "nástupní plat 28.000,- až 36.000, - + prémie",
                "rostoucí mzdové ohodnocení dle přínosu",
                "práce v přátelském kolektivu",
                "příspěvek na závodní stravování",
                "2 dny sickdays",
                "zaměstnanecká sleva",
                "firemní SIM karta",
            ]
        }

    ]
    // const [jobs, setjobs] = useState<any[]>([])
    // const [jobsLoaded, setJobsLoaded] = useState(false)
    // useEffect(() => {
    //     setJobsLoaded(false)
    //     fetch(`${process.env.REACT_APP_STRAPI_URL}/api/jobs/`, {
    //         headers: {
    //             Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
    //         }
    //     })
    //     .then(response => response.json())
    //     .then(jbs => {setjobs(jbs.data); setJobsLoaded(true)})
    // }, []);

    return (
        <>
            <Hero
                icon={{
                    iconComponent: FaChevronCircleDown,
                    iconOnClick: () => scrollToRef(document.querySelector("#contentContainer")),
                }}
                background="/images/kariera.jpg"
                title="Kariéra"
                subtitle="Přidejte se k nám"
            />
            <div className={cx("page-container, career")}>
                <div className={cx("content-container", "career__content")} id="contentContainer">
                    <div className={cx("career__motivation")}>
                        <h2>Proč právě my?</h2>
                        <p>
                            Jsme malý rodinný podnik, který neustále inovuje a je otevřen všem novým nápadům. Při finančním ohodnocení je pro nás klíčový celkový přínos zaměstnance. Pojď do toho s námi!
                        </p>
                    </div>
                    <div className={cx("career__jobs")}>
                        <h2>Volné pozice</h2>
                        <div className={cx("career__jobs-wrapper")}>
                            {/* {!jobsLoaded && <ContentLoaderCareer/>} */}
                            {jobs.map(job => {
                                return (
                                    <Job jobName={job.jobName}
                                        jobDescription={job.jobDescription}
                                        requirements={job.requirements}
                                        offers={job.offers}
                                        className={cx("job")} />
                                )
                            })}
                        </div>
                    </div>
                    <div className={cx("career__more-info")}>
                        Pokud máte jakékoliv otázky, zavolejte na číslo <a href="tel:+420-775-555-822">775 555 822</a> a v případě zájmu nám prosím zašlete Váš životopis na e-mail: <a href="mailto:info@holoubektrade.cz">info@holoubektrade.cz</a>.
                    </div>
                </div>

                <HTFooter className={cx("footer")} />
            </div>
        </>

    )
}


export default Kariera