import classNames from "classnames/bind";
import Styles from "./Kontakt.module.scss";
import PersonalContact from "../../components/PersonalContact/PersonalContact";
import HTFooter from "../../components/HTFooter/HTFooter";

const Kontakt = () => {
  const cx = classNames.bind(Styles);

  return (
    <>
      <div className={cx("kontakt-page__wrapper")}>
        <div className={cx("kontakt-page")}>
          <div className={cx("kontakt-page__title-wrapper")}>
            <h2>Kontakt</h2>
          </div>
          <div className={cx("kontakt-page__content-wrapper")}>
            <div className={cx("kontakt-page__content-item")}>
              <div className={cx("kontakt-page__operator")}>
                <h3>Obecné kontakty</h3>
                <p>
                  <a
                    target="_blank"
                    href="tel:420-775-555-830"
                    rel="noreferrer"
                  >
                    +420 775 555 830
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    href={`tel:+420-775-555-831`}
                    rel="noreferrer"
                  >
                    +420 775 555 831
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    href="fax:+420-566-535-037"
                    rel="noreferrer"
                  >
                    Fax: +420 566 535 037
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    href="mailto:info@holoubektrade.cz"
                    rel="noreferrer"
                  >
                    info@holoubektrade.cz
                  </a>
                </p>
              </div>
              <div className={cx("kontakt-page__warehouse")}>
                <h3>Podnikový prodejní sklad</h3>
                <p>Holoubek trade s.r.o.</p>
                <p>Jamská 2528/71</p>
                <p>591 01 žďár nad sázavou</p>
              </div>
              <div className={cx("kontakt-page__billing")}>
                <h3>Fakturační údaje</h3>
                <p>Holoubek trade s.r.o.</p>
                <p>Jamská 2528/71</p>
                <p>591 01 žďár nad sázavou</p>
                <p>IČ: 28284551</p>
                <p>DIČ: cz28284551</p>
              </div>
            </div>
            <div className={cx("kontakt-page__content-item")}>
              <PersonalContact
                name="Petr Toman"
                job="obchod přívěsy"
                phone="+420 773 555 284"
                phone_href="+420-773-555-284"
                mail="toman@holoubektrade.cz"
              />
              <PersonalContact
                name="Hana Holoubková"
                job="obchod ploty brány"
                phone="+420 775 555 821"
                phone_href="+420-775-555-821"
                mail="holoubkova@holoubektrade.cz"
              />
              <PersonalContact
                name="Rostislav Mošner"
                job="nákup"
                phone="+420 775 555 829"
                phone_href="+420-775-555-829"
                mail="mosner@holoubektrade.cz"
              />
              <PersonalContact
                name="Marie Dvořáková"
                job="účetnictví"
                phone="+420 775 555 836"
                phone_href="+420-775-555-836"
                mail="faktury@holoubektrade.cz"
              />
            </div>
          </div>
        </div>
        <HTFooter className={cx("footer")}/>
      </div>
    </>
  );
};

export default Kontakt;
