import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/Home/Home";
import KovovyrobaPage from "./pages/Kovovyroba/Kovovyroba";
import GDPRPage from "./pages/GDPR/GDPR";
import KontaktPage from "./pages/Kontakt/Kontakt"
import KarieraPage from "./pages/Kariera/Kariera"
import NotFound from "./pages/NotFound/NotFound";

import { BrowserRouter, Route, Routes } from "react-router-dom";


const navbarItems = [
  {
    title: "O nás",
    path: "/",
  },
  {
    external: true,
    title: "Přívěsy",
    path: "https://www.privesy-holoubek.cz/",
  },
  {
    title: "Kovovýroba",
    path: "/kovovyroba",
  },
  {
    external: true,
    title: "Ploty-Brány",
    path: "https://www.ploty-brany.cz/",
  },
  {
    title: "Kariéra",
    path: "/kariera",
  },
  {
    title: "Kontakt",
    path: "/kontakt",
  },
];

function App() {
  return (
    <BrowserRouter>
      <>
        <Navbar
          links={navbarItems}
          headerImage="/images/logo-holoubektrade.png"
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/kovovyroba" element={<KovovyrobaPage />} />
          <Route path="/kariera" element={<KarieraPage />} />
          <Route path="/kontakt" element={<KontaktPage />} />
          <Route path="/gdpr" element={<GDPRPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
