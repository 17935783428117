import Styles from "./ContentBox.module.scss";
import classNames from "classnames/bind";
import Button from "../Button/Button"
interface ContentBoxProps {
  imageUrl: string;
  title: string;
  button?: buttonProps;
  variant: "metalwork" | "trailers" | "fences" | "default"
  html: React.ReactNode;
  imageFirst: boolean;
  className?: string;
}

interface buttonProps {
  buttonText: string;
  buttonLink: string;
}

const ContentBox = ({
  imageUrl,
  title,
  imageFirst,
  html,
  button,
  variant,
  className,
}: ContentBoxProps) => {
  const cx = classNames.bind(Styles);

  return (
    <div
      className={cx(
        className,
        "content-box",
        imageFirst ? "content-box--default" : "content-box--reverse",
        `variant-${variant}`
      )}
    >
      <div
        className={cx("content-box__image")}
        style={{
          backgroundImage: `linear-gradient(${imageFirst ? `to right` : `to left`
            }, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 100%), url(${imageUrl})`,
        }}
      ></div>
      <div
        className={cx(
          "content-box__content",
          imageFirst
            ? "content-box__content--right"
            : "content-box__content--left"
        )}
        style={{
          backgroundImage: `linear-gradient(${imageFirst ? `to right` : `to left`
            }, rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.6) 100%), url(${imageUrl})`,
        }}
      >
        <div className={cx("content-box__content-wrapper")}>
          <h1
            className={cx("content-box__title", `content-box__title--${variant}`)}
          >
            {title}
          </h1>
          <div className={cx("content-box__description")}> {html}</div>
        </div>
        {button &&
          <div className={cx("content-box__button-container")}>
            <Button variant={variant} href={button.buttonLink} id="contentbox-button" className={cx("content-box__button")}>
              {button.buttonText}
            </Button>
          </div>
        }
      </div>
    </div>
  );
};

export default ContentBox;
