import Styles from "./DemandForm.module.scss";
import classNames from "classnames/bind";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Button from "../Button/Button";
interface IDemandForm {
  variant: "metalwork" | "trailers" | "fences";
  gdprLink: string;
  className?: string;
}

type FormValues = {
  firstname: string;
  lastname: string;
  senderEmail: string;
  emailText: string;
  attachment: FileList;
};

const DemandForm = ({ variant, className, gdprLink }: IDemandForm) => {
  const onSubmit = (data: FormValues) => {
    (() => {
      setisSubmitting(true);
      setsubmitFailed(false);
      setsubmittedSuccessfully(false);
      const formData = new FormData();
      formData.append("firstname", data.firstname);
      formData.append("lastname", data.lastname);
      formData.append("senderEmail", data.senderEmail);
      formData.append("emailText", data.emailText);
      Array.from(data.attachment).forEach((attachment) => {
        formData.append("attachmentFiles", attachment);
      });
      fetch("https://holoubek-trade-backend-5771.rostiapp.cz/sendMail", {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          setisSubmitting(false);
          if (res.status === 200) {
            setsubmittedSuccessfully(true);
            reset({
              firstname: "",
              lastname: "",
              senderEmail: "",
              emailText: "",
              attachment: new DataTransfer().files,
            });
          } else {
            setsubmitFailed(true);
          }
        })
        .catch((err) => {
          setisSubmitting(false);
          setsubmitFailed(true);
        });
    })();
  };

  const [isSubmitting, setisSubmitting] = useState(false);
  const [submittedSuccessfully, setsubmittedSuccessfully] = useState(false);
  const [submitFailed, setsubmitFailed] = useState(false);

  const cx = classNames.bind(Styles);
  const { register, handleSubmit, watch, reset } = useForm<FormValues>();
  return (
    <div
      className={cx(
        className,
        "demand-form-container",
        "demand-form--separated",
        `variant-${variant}`
      )}
    >
      <h2>Poptat výrobu</h2>
      <form
        id="demandForm"
        className={cx("demand-form")}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={cx("demand-form__inputs-flex-2")}>
          <div className={cx("demand-form__input-container")}>
            <label htmlFor="firstname">Jméno</label>
            <input
              type="text"
              id="firstname"
              placeholder="Zadejte Vaše jméno"
              required
              {...register("firstname")}
            />
          </div>
          <div className={cx("demand-form__input-container")}>
            <label htmlFor="lastname">Příjmení</label>
            <input
              type="text"
              id="lastname"
              placeholder="Zadejte Vaše příjmení"
              required
              {...register("lastname")}
            />
          </div>
        </div>
        <div className={cx("demand-form__input-container")}>
          <label htmlFor="email">Váš email</label>
          <input
            type="email"
            id="email"
            placeholder="Zadejte Váš email"
            required
            {...register("senderEmail")}
          />
        </div>
        <div
          className={cx("demand-form__input-container", "textarea-container")}
        >
          <label htmlFor="emailText">Poptávka</label>
          <textarea
            id="emailText"
            placeholder="Zadejte poptávku"
            required
            {...register("emailText")}
          />
        </div>
        <div className={cx("demand-form__gdpr-agreement")}>
          <p>
            Odesláním formuláře souhlasíte se{" "}
            <a href={gdprLink}>zpracováním osobních údajů</a>
          </p>
        </div>
        <div className={cx("demand-form__buttons")}>
          <label
            htmlFor="attachment"
            className={cx("demand-form__attachment-btn-label")}
          >
            <Button
              variant="metalwork"
              id="attachment-upload-btn"
              className={cx("demand-form__attachment-upload-btn")}
            >
              <AiOutlineCloudUpload /> Přidat přílohu
              {watch("attachment") && watch("attachment").length !== 0
                ? " (" + watch("attachment").length + ")"
                : ""}
              <input
                type={"file"}
                multiple
                id="attachment"
                hidden
                {...register("attachment")}
              />
            </Button>
          </label>
          <Button id="submit-btn" disabled={isSubmitting} variant="metalwork">
            {isSubmitting ? "Odesílání..." : "Odeslat"}
          </Button>
        </div>
        <p className={cx("demand-form__submit-status")}>
          {submittedSuccessfully && "Poptávka úspěšně odeslána"}
          {submitFailed &&
            "Odeslání poptávky se nezdařilo, zkuste to prosím znovu"}
        </p>
      </form>
    </div>
  );
};

export default DemandForm;
