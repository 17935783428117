import  { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Hero from "../../components/Hero/Hero";
import HTFooter from "../../components/HTFooter/HTFooter";
import Styles from "./Home.module.scss";
import { FaChevronCircleDown } from "react-icons/fa";
import classNames from "classnames/bind";
import { polyfill, scrollIntoView } from "seamless-scroll-polyfill";

const Home = () => {
  const cx = classNames.bind(Styles);

  //scrolls to referenced element
  const scrollToRef = (ref: Element | null) => {
    if (ref != null) {
      scrollIntoView(ref, { behavior: "smooth", block: "start" });
    }
  };

  //load element references for smooth scroll
  useEffect(() => {
    polyfill();
  }, [])
  return (
    <>
      <Hero
        icon={{
          iconComponent: FaChevronCircleDown,
          iconOnClick: () => scrollToRef(document.querySelector("#contentContainer")),
        }}
        background="/images/pohled-na-firmu.jpg"
        title="Kvalitní česká výroba Holoubek"
        subtitle="Prozkoumejte blíže naši výrobu"
      />
      <div className={cx("page-container")}>
        <div className={cx("content-container")} id="contentContainer" >
          <ContentBox
            imageFirst={false}
            imageUrl="/images/vozik-hlavni.jpg"
            html={<p>Prodáváme kvalitní přívěsy kompletně vyrobené u nás.</p>}
            title="Přívěsy"
            variant="trailers"
            button={{
              buttonLink: "https://www.privesy-holoubek.cz/",
              buttonText: "Prozkoumat",
            }}
            className="content-box--separated"
          />
          <ContentBox
            imageFirst={true}
            imageUrl="/images/kovovyroba-hlavni.jpg"
            html={<p>Zpracováváme kovové produkty dle Vašeho zadání již od roku 2006.</p>}
            title="Kovovýroba"
            variant="metalwork"
            className="content-box--separated"
            button={{ buttonLink: "/kovovyroba", buttonText: "Prozkoumat" }}
          />
          <ContentBox
            imageFirst={false}
            imageUrl="/images/3d-plot.jpg"
            html={<p>Dodáváme vše potřebné ke stavbě Vašeho oplocení včetně bran.</p>}
            title="Ploty-Brány"
            variant="fences"
            className={"content-box--separated"}
            button={{
              buttonLink: "https://www.ploty-brany.cz/",
              buttonText: "Prozkoumat",
            }}
          />
        </div>
            <HTFooter className={cx("footer")}></HTFooter>
      </div>
    </>
  );
};

export default Home;
