import classNames from "classnames/bind";
import Styles from "./GDPR.module.scss";
import HTFooter from "../../components/HTFooter/HTFooter";

const GDPR = () => {
  const cx = classNames.bind(Styles);

  return (
    <>
      <div className={cx("gdpr-page-wrapper")}>
        <div className={cx("gdpr-content-wrapper")}>
          <h1>Ochrana osobních údajů zákazníků</h1>
          <p>
            Společnost <strong>Holoubek trade s.r.o.</strong>, se sídlem Jamská 2528/71, 591 01
            Žďár nad Sázavou, IČ: 28284551, DIČ: CZ28284551, (dále jen
            <strong>"správce"</strong>) z pozice správce dle GDPR zpracovává u zákazníků osobní
            údaje v rozsahu:
          </p>
          <ul>
            <li>Jméno</li>
            <li>Příjmení</li>
            <li>E-mail</li>
            <li>IP adresa</li>
          </ul>
          <p>
            Uvedené osobní údaje je nutné zpracovat na základě smluvního vztahu,
            pro realizaci zakázky (Vaší objednávky zboží / služeb a jejího
            následného vyřízení), dle právního titulu uzavření a plnění smlouvy.
          </p>
          <p>
            Osobní údaje budou zpracovány po dobu realizace zakázky a následně
            archivovány z důvodu možnosti hájení práva a oprávněných zájmů
            správce po dobu 10 let.
          </p>
          <p>
            Osobní údaje jsou zpracovány v elektronické formě, v rámci interního
            systému a v tištěné formě v rámci řádně zabezpečeného archivu
            společnosti, a jsou řádně zabezpečené proti odcizení.
          </p>
          <p>
            Zpracování osobních údajů je prováděno správcem, který je v rámci
            zpracovatelských vztahů předává firmám (subdodavatelé správce):
          </p>
          <ul>
            <li>zajišťující dopravu objednaného zboží / služeb (Toptrans)</li>
            <li>zajišťující realizaci plateb (ThePay)</li>
            <li>
              zajišťující technický provoz serverů a další související služby
              nutné pro provoz www stránek / e-shopu (fa. Pavel Králíček, Satt
              a.s., fa. Ing. Zbyněk Holub)
            </li>
          </ul>
          <p>
            Vezměte prosím na vědomí, že podle zákona o ochraně osobních údajů
            máte právo po správcovi:
          </p>
          <ul>
            <li>
              požadovat informace, jaké osobní údaje společnost zpracovává
            </li>
            <li>požadovat vysvětlení ohledně zpracování osobních údajů</li>
            <li>požádat o aktualizaci nebo opravu osobních údajů</li>
            <li>požádat o výmaz evidovaných osobních údajů</li>
            <li>požádat o omezení zpracování</li>
            <li>
              v případě pochybností o dodržování povinností souvisejících se
              zpracováním osobních údajů obrátit na správce nebo Úřad na ochranu
              osobních údajů
            </li>
          </ul>
          <p>
          V případě námitek či jakýchkoliv dotazů se obraťte přímo na majitele společnosti p. Radka Holoubka - <strong>info@holoubektrade.cz</strong>.
          </p>
        </div>

        <HTFooter className={cx('footer')}/>
      </div>
    </>
  );
};

export default GDPR;
