import Styles from "./Button.module.scss";
import classNames from "classnames/bind";

export interface ButtonProps {
  id: string;
  href?: string;
  variant: "metalwork" | "trailers" | "fences" | "default";
  children: React.ReactNode;
  onClick?(): void;
  disabled?: boolean
  className?: string;
}

const Button = ({
  id,
  href,
  variant,
  children,
  disabled,
  onClick,
  className,
}: ButtonProps) => {
  const cx = classNames.bind(Styles);
  const Component = href ? "a" : "button";

  return (
    <Component
      className={cx("button", `button--${variant}`, disabled && "is--disabled", className)}
      onClick={onClick}
      href={href}
      id={id}
    >
      <span className={cx("button-children")}>{children}</span>
    </Component>
  );
};

export default Button;
