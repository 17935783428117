import classNames from "classnames/bind";
import Styles from "./NotFound.module.scss";

const NotFound = () => {
  const cx = classNames.bind(Styles);

  return (
    <div className={cx("centerContent")}>
      <h1>Error 404</h1>
      <h2> Page not found</h2>
    </div>
  );
};

export default NotFound;
