//use from https://stackoverflow.com/a/65008608
import { useEffect, useState, useMemo } from "react";


const useOnScreen = (ref: any) => {

    const [isIntersecting, setIsIntersecting] = useState(false)
    
    const observer = useMemo(() => {
        return new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting))
    }, []);

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    })

    return isIntersecting
}


export default useOnScreen;
